import React from 'react';
import './App.css';
import img from './img/brittanya.png';
function App() {
  return (
    <div className="App">
        <header className="App-header">
            <div className='container'>            
                <h3 className='title-site'><a href="https://streamx.tv/BrittanyaRazavi">Brittanya’s Secret Site</a></h3>
                <a href="https://streamx.tv/BrittanyaRazavi"><img src={img} className='img-resp' alt="brittanya"/></a>
                <a href="https://streamx.tv/BrittanyaRazavi" className='enter'>Enter</a>
            </div>
        </header>
    </div>
  );
}

export default App;
